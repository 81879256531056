import React, { useState, useEffect } from 'react';
import 'assets/styles/pages/sidebar.scss';
import 'assets/styles/pages/dashboardstatus.scss';
import Logo from 'assets/images/logo/forest-logo.png';
import { Navbar, Nav, Modal, Spinner, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, navigate } from 'gatsby';
import donatedtree from 'assets/images/admin/donated-tree.svg';
import planterprofilegreen from 'assets/images/admin/planter-profile-green.png';
import saplingplanned from 'assets/images/admin/sapling-planned.png';
import saplingdonated from 'assets/images/admin/sapling-donated.png';
import projectviewgreen from 'assets/images/admin/project-view-green.png';
import editiconpencil from 'assets/images/admin/edit-icon.svg';
import eyeicon from 'assets/images/admin/visibility.png';
import binicon from 'assets/images/admin/recycle-bin.png';
import locationicon from 'assets/images/admin/location.png';
import linkicon from 'assets/images/admin/link.png';
import projecticongrey from 'assets/images/admin/project-icon-grey.png';
import speciesgrey from 'assets/images/admin/species-grey.png';
import AdminService from 'services/admin/admin.service';
import { toast, ToastContainer } from 'react-toastify';
import Projectlocationpops from './projectlocationpops';
import moment from 'moment';
import noimage from "assets/images/noImage.png";
import PlantationListDetails from 'services/plantation/plantation.service';
import { doAdminLogout } from './login';

import Viewthubnailslider from 'components/register-plantation/viewthumbnail-slider';
import { isMobile } from 'react-device-detect';
import infoIcon from 'assets/images/icons/info-icon.png';
export default function Dashboardviewall() {

  const adminApi = new AdminService();
  const ds = new PlantationListDetails();
  const [dashBoardView, setDashBoardView] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [speciesList, setSpeciesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState("");
  const [mapOpen, setMapOpen] = useState(false);
  const [error, setError] = useState(false);
  const [projectValue, setProjectValue] = useState("");
  const [proValue, setProValue] = useState("");
  const [projectDataList, setProjectDataList] = useState([]);
  const [plantationList, setPlantationList] = useState([]);
  const [donorList, setDonorList] = useState([]);
  const [projectOpen, setProjectOpen] = useState(false);
  const [speciesOpen, setSpeciesOpen] = useState(false);
  const [projectId, setProjectId] = useState(false);
  const [speciesId, setSpeciesId] = useState(false);

  const projectDelete = (data) => {
    setProjectId(data)
    setProjectOpen(true);
  }
  const projectClose = () => {
    setProjectOpen(false);
    setProjectId('')
  }
  const speciesDelete = (data) => {
    setSpeciesId(data)
    setSpeciesOpen(true);
  }
  const speciesClose = () => {
    setSpeciesOpen(false);
    setSpeciesId('')
  }

  // Donor Section

  const listDonors = async () => {
    let inputs = {
      "pageNumber": 0,
      "pageSize": 10,
      "donationListFilter": 1,
      "toBeFiltered": false
    }
    setLoading(true);
    await adminApi.getDonationList({ inputs })
      .then(data => {
        if (data.status === 200) {
          const size = 3
          const items = data.data.donorDonationDetails.slice(0, size)
          setDonorList(items);
          setLoading(false);
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          validationMessage(error)
        }
      }
      );
  };

  const submitMap = async () => {
    let subForm = {
      "donationId": proValue.donationId,
      "projectId": projectValue
    }

    if (projectValue === "") {
      setError(true)
    }

    if (projectValue !== "") {
      setLoading(true)
      await adminApi.getMapDonation({ subForm })
        .then(data => {
          if (data.status === 200) {
            setLoading(false);
            toast.success("Species Mapped Successfully");
            closeModalpop()
            listDonors();
          } else {
            setLoading(false)
            toast.error("Something went wrong");
          }
        })
        .catch(error => {
          setLoading(false)
          if (error.response) {
            validationMessage(error)
          }
        }
        );
    }
  }

  const adminlistProjects = async () => {
    let inputs = {
      "pageNumber": 0,
      "pageSize": 2,
      "name": "",
      "status": "",
      "toBeFiltered": false
    }
    setLoading(true);
    await adminApi.listProjects({ inputs })
      .then(data => {
        if (data.status === 200) {
          setLoading(false);
          setProjectList(data.data);
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          validationMessage(error)
        }
      }
      );
  }

  const loadProjectList = async () => {
    let inputs = {
      "pageNumber": 0,
      "pageSize": 10,
      "name": "",
      "status": "",
      "toBeFiltered": false
    }
    setLoading(true);
    await ds.projectList({ inputs })
      .then(data => {
        if (data.status === 200) {
          setProjectDataList(data.data.projects);
          setLoading(false)
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          validationMessage(error)
        }
      }
      );
  };
  const mapProject = (data) => {
    setMapOpen(true)
    setProValue(data)
  }
  const selctPr = (e) => {
    setProjectValue(e.target.value);
    if (e.target.value !== "") {
      setError(false)
    }
  }
  const validationMessage = (data) => {
    if (data.response.status === 401) {
      toast.error("Authentication failed");
      setTimeout(() => {
        doAdminLogout()
        navigate("/admin/")
      }, 3000)
    } else if (data.response.status === 403) {
      toast.error("Un Authorized user");
      setTimeout(() => {
        doAdminLogout()
        navigate("/admin/")
      }, 3000)
    } else {
      toast.error(data.response.data.message);
    }
  }
  const closeModalpop = () => {
    setMapOpen(false);
    setProjectValue("");
    setError(false)
  }
  const loadDashboard = async () => {
    setLoading(true);
    await adminApi.adminDashboardmetrics()

      .then(data => {
        if (data.status === 200) {
          setDashBoardView(data.data);
          setLoading(false);
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          validationMessage(error)
        }
      }
      );
  };

  const deleteProject = async () => {
    setLoading(true);
    await adminApi.deleteProjects(projectId)
      .then(data => {
        if (data.status === 200) {
          setLoading(false);
          toast.success("Project Deleted Successfully");
          setTrackDelete(false);
          listPlanters();
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          validationMessage(error);
        }
      }
      );
  }
  const editProject = (data) => {
    const editData = data
    navigate("/admin/projectedit", { state: { editData } })
  }
  const deleteSpecies = async () => {
    setLoading(true);
    await adminApi.deleteAdminSpecies(speciesId)
      .then(data => {
        if (data.status === 200) {
          setLoading(false);
          toast.success("Species Deleted Successfully");
          setSpeciesOpen(false)
          listSpecies();
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          validationMessage(error)
        }
      }
      );
  }
  const editSpecies = (data) => {
    const editData = data
    navigate("/admin/speciesedit", { state: { editData } })
  }
  const listPlantations = async () => {
    let inputs = {
      "pageNumber": 0,
      "pageSize": 2,
      "reviewStatus": 0,
      "projectId": 0,
      "toBeFiltered": false

    }
    setLoading(true);
    await adminApi.getPlantationList({ inputs })
      .then(data => {
        if (data.status === 200) {
          setPlantationList(data.data.plantationDetails);
          setLoading(false)
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          validationMessage(error)
        }
      }
      );
  };
  const listSpecies = async () => {
    let inputs = {
      "pageNumber": 0,
      "pageSize": 2,
      "name": "",
      "status": "",
      "toBeFiltered": false
    }
    setLoading(true);
    await adminApi.listAdminSpecies({ inputs })
      .then(data => {
        if (data.status === 200) {
          setSpeciesList(data.data);
          setLoading(false)
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          validationMessage(error)
        }
      }
      );
  };

  const deleteOpenTab = (data) => {
    setDeleteOpen(true);
    setId(data)
  };
  const handleClose = () => setDeleteOpen(false);
  const deletePlan = async () => {
    setLoading(true);
    await adminApi.deletePlantation(id).then(data => {
      if (data.status === 200) {
        toast.success("Plantation Deleted Successfully");
        listPlantations();
        setLoading(false)
        handleClose();
      } else {
        setLoading(false)
        toast.error("Something went wrong");
      }
    })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          validationMessage(error)
        }
        handleClose();
      }
      );
  }
  const viewPage = (data, type) => {
    const editData = data
    editData.type = type
    navigate("/admin/adminview/", { state: { editData } })
  }
  useEffect(() => {
    loadDashboard();
    listPlantations();
    listSpecies();
    loadProjectList();
    adminlistProjects();
    listDonors();
    const uDetails = (typeof window !== 'undefined' && window.localStorage) ? localStorage.getItem("hfnAuthUserDetailsAdmin") : null
    if (uDetails === null && uDetails === "") {
      navigate("/admin")
    }
  }, []);

  return (
    <>
      <div className='dashboard-status-wrapper '>
        <div className='row'>
          <div className='col-md-22'>
            <h3 className='dark-theme-green'> Dashboard</h3>
            <div className='plant-padding'>
              <div className='planter-list-row-view'>
                <span className='plant-thum-wid'>
                  <img src={planterprofilegreen} alt='Planter thumb' />
                </span>
                <p className='planter-list-heading'> Planters</p>
                <div className='planter-row-item'>
                  <h4> {dashBoardView.totalNoOfPlanters}</h4>
                </div>
              </div>
              <div className='planter-list-row-view'>
                <span className='plant-thum-wid'>
                  <img src={saplingplanned} alt='Planter thumb' />
                </span>
                <p className='planter-list-heading'> Saplings Planted </p>
                <div className='planter-row-item'>
                  <h4> {dashBoardView.totalNoOfSpeciesPlanted} </h4>
                </div>
              </div>
              <div className='planter-list-row-view'>
                <span className='plant-thum-wid'>
                  <img src={saplingdonated} alt='Planter thumb' />
                </span>
                <p className='planter-list-heading'> Saplings Donated </p>
                <div className='planter-row-item'>
                  <h4> {dashBoardView.noOfSaplingsDonated}</h4>
                </div>
              </div>

              <div className='planter-list-row-view'>
                <span className='plant-thum-wid'>
                  <img src={saplingdonated} alt='Planter thumb' />
                </span>
                <p className='planter-list-heading'> Saplings Gifted</p>
                <div className='planter-row-item'>
                  <h4> {dashBoardView.noOfSaplingsGifted} </h4>
                </div>
              </div>

              <div className='planter-list-row-view'>
                <span className='plant-thum-wid'>
                  <img src={projectviewgreen} alt='Planter thumb' />
                </span>
                <p className='planter-list-heading'> Projects </p>
                <div className='planter-row-item'>
                  <h4>{dashBoardView.noOfProjects} </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row mg-top20'>
          <div className='col-md-10'>
            <h3 className='recently-text'> New Plantation Details</h3>
          </div>
          <div className='col-md-2'>
            <Link className='view-all' style={{ textDecoration: 'none' }} to='/admin/admindashboardstatus'>
              <button className='btn btn-primary'>  View All</button>
            </Link>
          </div>
        </div>

        <div className='row mg-top20'>
          {plantationList && plantationList.totalNoOfRecords !== 0 ? <>
            {plantationList && plantationList.map(item => {
              let loopImages = item.plantation.trackingDetails && item.plantation.trackingDetails[0] ? item.plantation.trackingDetails[0] : ""
              return (<div className='col-md-6' key={item.plantation.id}>
                <div className='dashboard-card-wrapper admin-padding-6'>
                  <div className='wid-40 padding-10' id="plantList">
                    {loopImages.imagesLogs && loopImages.imagesLogs ? <Viewthubnailslider images={loopImages.imagesLogs} key={item.id} /> : <img src={noimage} alt="default Image" />}
                  </div>
                  <div className='dashboard-card-body wid-60'>
                    <div className='dis-title-status'>
                      <div className='heading-text'> {item.plantation.project.name}</div>
                    </div>

                    <p className='small-tree-text'><span className='mg-right60'>Total Trees</span> {item.totalSpeciesCount}</p>
                    <p className='small-tree-text'><span className='mg-right45'>Available Trees</span> {item.availableSpeciesCount}</p>
                    <div className='mgtop10'></div>
                    <p className='reg-id-num ellispe'> Reg Id # {item.plantation.registrationId} </p>
                    <p className='date-time-text'> Planted by : <b>{item.plantation.planterName}</b> </p>
                    <p className='date-time-text'> Registered by : <b>{item.plantation.plantationRegistrarName} </b></p>
                    <p className='date-time-text'> Reviewed by : <b>{item.plantation.reviewedBy ? item.plantation.reviewedBy : "Not Yet"} </b></p>
                    <p className='date-time-text'> Mobile No : {item.plantation.planterPhoneNumber} </p>
                    <div className='item-direction-wr1'>
                      <p className='date-time-text p-wid70'> {moment(new Date(item.plantation.plantationDate)).format(("ddd, MMM D YYYY"))} </p>
                      <span className='sp30'>

                        <button className='circle-view margin-right-10' to='/admin/adminview/' onClick={() => { viewPage(item, "view") }}>

                          <img className='img-fluid eye-max-w' src={eyeicon} alt='bellicon' />
                        </button>
                        {item.plantation.reviewStatus === "IN_PROGRESS" || item.plantation.isGrowthStatusModified === true ? <button className='circle-view' onClick={() => { viewPage(item, "edit") }}>

                          <img className='img-fluid eye-max-w' src={editiconpencil} alt='bellicon' />
                        </button> : null}
                        {item.plantation.reviewStatus === "REJECTED" ? <button className='circle-view-admin delete-bin' onClick={() => { deleteOpenTab(item.plantation.id) }}>
                          <img className='img-fluid bin-w-pad' height="40px" width="25px" src={binicon} alt='bellicon' />
                        </button> : null}
                      </span>
                    </div>
                    {item.plantation.isGrowthStatusModified === true ?
                      <p className='date-time-text track-color '>Modified plantation waiting for approval</p> : null}
                    {item.plantation.reviewStatus === "IN_PROGRESS" ?
                      <p className='date-time-text track-color'>New plantation waiting for approval</p> : null}
                    {item.plantation.reviewStatus === "REJECTED" ?
                      <p className='date-time-text hastric-color'>Plantation has Rejected &nbsp;
                        <OverlayTrigger
                          key='top'
                          placement='top'
                          overlay={
                            <Tooltip id='tooltip-top'>
                              <strong>{item.plantation.reviewComment}</strong>.
                            </Tooltip>
                          }
                        >
                          <img src={infoIcon} alt="info" width="18px" height="18px" /></OverlayTrigger>
                      </p> : null}
                  </div>
                </div>
              </div>)
            })}</> : <p>No Records Found</p>}
        </div>
        <hr />

        <div className='row mg-top20'>
          <div className='col-md-10'>
            <h3 className='recently-text'> New Projects</h3>
          </div>
          <div className='col-md-2'>
            <Link className='view-all' style={{ textDecoration: 'none' }} to='/admin/projectstatus'>
              <button className='btn btn-primary'> View All</button>
            </Link>
          </div>
        </div>

        <div className='row mg-top20'>
          {projectList.projects && projectList.projects.map(projects => {
            return (<div className='col-md-6'>
              <div className='dashboard-card-wrapper admin-padding-6'>
                <div className='wid-40 padding-10' id="plantList">
                  {projects.projectImageUrls && projects.projectImageUrls ?
                    <img className='img-fluid' src={projects.projectImageUrls && projects.projectImageUrls[0]} alt='bellicon' height="198px" /> : <img src={noimage} alt="default Image" />}
                </div>
                <div className='dashboard-card-body wid-60'>
                  <div className='dis-title-status'>
                    <div className='heading-text'>{projects.name}</div>
                    <span className='status-text right-hightlight-msg'>
                      {projects.status === "INACTIVE" ? <span style={{ color: "red" }}>Inactive</span> : projects.status === "ACTIVE" ? "Active" : null}</span>
                  </div>
                  <div className='dis-title-status'>
                    <span className='small-tree-text mg-right40'> Total Trees</span>
                    <span className='small-tree-text'> {projects.totalSpeciesCount} </span>
                  </div>
                  <p className='date-time-text'>Mobile No : {projects && projects.phoneNumber ? projects.phoneNumber : ""} </p>
                  <p className='date-time-text ellispe'>Email Id :  {projects && projects.email ? projects.email : ""}</p>
                  <p className='date-time-text'>  {moment(new Date(projects.projectStartDate)).format('llll')} </p>

                  <div className={isMobile ? `item-direction-wr1` : `item-direction-wr right-alig-icon`}>
                    <Projectlocationpops data={[projects.latitude, projects.longitude]} />

                    <span className='gap-circle-icon'>
                      {' '}
                      <button className='circle-view' to='/admin/projectedit' onClick={() => { editProject(projects) }}>
                        {' '}
                        <img className='img-fluid' src={editiconpencil} alt='bellicon' />
                      </button>{' '}
                    </span>
                    <span className='gap-circle-icon'>
                      {' '}
                      <button className='circle-view' to='/admin/dashboardview'>
                        {' '}
                        <img className='img-fluid bin-w-pad' onClick={() => { projectDelete(projects.id) }} src={binicon} alt='bellicon' />
                      </button>{' '}
                    </span>
                  </div>
                </div>
              </div>
            </div>)
          })}
        </div>

        <hr />

        <div className='row mg-top20'>
          <div className='col-md-10'>
            <h3 className='recently-text'> New Species</h3>
          </div>
          <div className='col-md-2'>
            <Link className='view-all' style={{ textDecoration: 'none' }} to='/admin/speciesstatus/'>
              <button className='btn btn-primary'>View All</button>
            </Link>
          </div>
        </div>

        <div className='row mg-top20'>
          {speciesList.species && speciesList.species.map(item => {
            return (<div className='col-md-6'>
              <div className='dashboard-card-wrapper admin-padding-6'>
                <div className='wid-40 padding-10' id="plantList">
                  {item.speciesImageUrls && item.speciesImageUrls[0] !== undefined ?
                    <img className='' src={item.speciesImageUrls[0]} alt='bellicon' /> : <img src={noimage} alt="default Image" />}
                </div>
                <div className='dashboard-card-body wid-60'>
                  <div className='dis-title-status'>
                    <div className='heading-text margin-btm-pad-10'> {item.speciesName}</div>
                    <span className='status-text right-hightlight-msg'> {item.status === "INACTIVE" ? <span style={{ color: "red" }}>Inactive</span> : item.status === "ACTIVE" ? "Active" : null}</span>
                  </div>
                  <div className='dis-title-status margin-btm-pad-10'>
                    <span className='small-tree-text mg-right40'>
                      {' '}
                      <img className='img-fluid' src={donatedtree} alt='bellicon' /> {item.scientificName}{' '}
                    </span>
                  </div>
                  <p className='date-time-text margin-btm-pad-10'> {item.naturalHabitat}</p>
                  <div className='mg-top35'></div>
                  <div className='mg-top35'></div>

                  <div className={isMobile ? `item-direction-wr1` : `item-direction-wr`}>
                    {/* <p className='date-time-text p-wid70'> Tue, Apr 20, 2021, 12:45 PM </p> */}
                    <span className='dis-icon-proper'>
                      <button className='circle-view mg-right10 edit-pencil-green' onClick={() => { editSpecies(item) }}>
                        {' '}
                        <img className='img-fluid' src={editiconpencil} alt='bellicon' />{' '}
                      </button>
                      <button className='circle-view delete-bin' onClick={() => { deleteSpecies(item.id) }}>
                        {' '}
                        <img className='img-fluid bin-w-pad' src={binicon} alt='bellicon' />{' '}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>)
          })}


        </div>
        <hr />

        <div className='row mg-top20'>
          <div className='col-md-10'>
            <h3 className='recently-text'> New Donors</h3>
          </div>
          <div className='col-md-2'>
            <Link className='view-all' style={{ textDecoration: 'none' }} to='/admin/donorlist'>
              <button className='btn btn-primary'>View All</button>
            </Link>
          </div>
        </div>

        <div className='row mg-top20'>
          {donorList && donorList.map(item => {
            return (
              <div className='col-md-4'>
                <div className='dashboard-card-wrapper admin-padding-6'>
                  <div className='dashboard-card-body wid100 dashboard-card-body-card'>
                    <div className='dis-title-status'>
                      <div className='heading-text'> {item.donorName}</div>
                      <span className='right-hightlight-msg reject-color'> {item.isSaplingMapped === true ? <span style={{ color: "green" }}>Mapped</span> : "Not-mapped"}</span>
                    </div>
                    <div className='dis-title-status'>
                      <span className='small-tree-text mg-right40'>
                        {' '}
                        <img className='img-fluid' src={donatedtree} alt='bellicon' /> Donated Trees{' '}
                      </span>
                      <span className='small-tree-text'> {item.donatedTrees} </span>
                    </div>
                    <div className='donor-list-padding'>
                      {/* <p className='date-time-projectName'> {item.projectName} </p> */}
                      <p className='date-time-text ellispe'> {item.donorEmailAddress}</p>
                    </div>
                    <div className='item-direction-wr1'>
                      <p className='date-time-text p-wid70'> {moment(item.donationDate).format("llll")}</p>
                      <span className='sp30'>
                        {item.isSaplingMapped === false ? <a className={`circle-view margin-left-70${item.isSaplingMapped === true ? "disabled" : ""}`} onClick={() => { mapProject(item) }}>
                          {' '}
                          <img className='img-fluid eye-max-w' src={linkicon} alt='bellicon' />{' '}
                        </a> : null}
                      </span>
                    </div>
                  </div>
                </div>
              </div>)
          })}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>

      <Modal show={deleteOpen} centered>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Plantation Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete</Modal.Body>
        <Modal.Footer>
          <Button variant="whiteColor" style={{ color: "black" }} onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={deletePlan}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={mapOpen}
        aria-labelledby='contained-modal-title-vcenter'
        className='custom-species-popup'
        centered>
        <Modal.Header closeButton onClick={() => { closeModalpop() }}>
          <h5> Project Mapping </h5>
        </Modal.Header>
        <Modal.Body>
          {/* <h3 className='sub-heading-title' style={{ marginLeft: '10px' }}>
            {' '}
            Donated Project : {proValue.projectName}
          </h3> */}
          <div className='register-plantation'>
            <div className='container'>
              <div className='row'>
                <form>
                  <div className='row'>
                    <div className='col-md-22'>
                      <div className='fc-wrapper'>
                        <label htmlFor='name' className='form-label'>
                          Mapping Project
                        </label>
                        <select name='projectName' id='projectName' required className='form-control'
                          onChange={(e) => { selctPr(e) }} >
                          <option value="">Select Project</option>
                          {projectDataList.map((item) => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.name} ({item.availableSpeciesCount})
                              </option>
                            );
                          })}
                        </select>
                        {error === true ? <p style={{ color: "red" }}>Please select Project</p> : null}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => { closeModalpop() }} variant="whiteColor" style={{ color: 'black' }}>Close</Button>
          {/* <Link to='/admin/donorlist' className='btn btn-approve save-width posi-close-top'>
            Submit
          </Link> */}
          <Button onClick={() => { submitMap() }} className='btn btn-approve save-width posi-close-top'>
            Submit
          </Button>

        </Modal.Footer>
      </Modal>
      <Modal show={projectOpen} centered>
        <Modal.Header closeButton onClick={projectClose}>
          <Modal.Title>Delete Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete ?</Modal.Body>
        <Modal.Footer>
          <Button variant="whiteColor" style={{ color: "black" }} onClick={projectClose}>
            No
          </Button>
          <Button variant="primary" onClick={deleteProject}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={speciesOpen} centered>
        <Modal.Header closeButton onClick={speciesClose}>
          <Modal.Title>Delete Species</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete ?</Modal.Body>
        <Modal.Footer>
          <Button variant="whiteColor" style={{ color: "black" }} onClick={speciesClose}>
            No
          </Button>
          <Button variant="primary" onClick={deleteSpecies}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}
