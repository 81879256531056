import React from 'react';

import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

import 'assets/styles/pages/plantationregister.scss';

import 'assets/styles/pages/plantationgrid.scss';


export default function Viewthubnailslider(props) {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 1,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 1,
      //partialVisibilityGutter: 130,
    },
  };
  const imagesList = props.images
  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className='carousel-button-group'>
        {/* <div className='navigations'>
              <button onClick={() => previous()} className='me-3'>
                <i className='icon icon-arrow-left-short'></i>
              </button>
              <button onClick={() => next()}>
                <i className='icon icon-arrow-right-short'></i>
              </button>
            </div> */}
      </div>
    );
  };

  return (
    <>
      <div className='intro-carousel2' id="carousel-bg-color">
        <Carousel
          arrows={true}
          minimumTouchDrag={80}
          partialVisible
          renderButtonGroupOutside={false}
          swipeable
          infinite
          responsive={responsive}
        // customButtonGroup={<ButtonGroup />}
        >
          {imagesList && imagesList.map(file => {
            return (<div className='img-wrapper padding-10' key={file.id}>
              <img src={file.imageUrl} className='lazyload' alt='path tree' />
            </div>)
          })}

          {/* <div className='img-wrapper'>
            <img data-src={labour} className='lazyload' alt='path tree' />
          </div> */}

        </Carousel>
      </div>
    </>
  );
}
