import React from 'react';

import { GoogleMap, LoadScript } from '@react-google-maps/api';

import { Marker } from '@react-google-maps/api';

import treeidentify from 'assets/images/map-initiative/tree-identify.png';
import config from 'assets/config';

const containerStyle = {
  width: '100%',
  height: '650px',
};
export default function Googlemaps(props) {
  let dataValues;
  if (props.mapValues && props.mapValues.mapValues) {

    dataValues = (props.mapValues && props.mapValues !== undefined) ? props.mapValues.mapValues : "";
  } else if (props.mapValues.data !== undefined && props.mapValues.data !== null && props.mapValues.data.data !== undefined) {
    dataValues = (props.mapValues && props.mapValues.data !== undefined && props.mapValues.data.data !== undefined) ? props.mapValues.data.data.data : "";
  } else {
    dataValues = (props.mapValues && props.mapValues.data !== undefined) ? props.mapValues.data && props.mapValues.data.type === "trackNow" ? props.mapValues.data.plantationTrackingDetails[0] : props.mapValues && props.mapValues !== undefined ? props.mapValues.data : props.data.mapValues : "";
  }
  const center = {
    lat: dataValues && dataValues.latitude !== "" ? dataValues.latitude : "",
    lng: dataValues && dataValues.longitude !== "" ? dataValues.longitude : "",
    clickableIcons: true,
  };

  const markers = [
    {
      location: { lat: dataValues && dataValues.latitude !== "" ? dataValues.latitude : "", lng: dataValues && dataValues.longitude !== "" ? dataValues.longitude : "" },
      place_id: 0,
      title: 'Your Sapling Planted Here',
    },
    {
      location: { lat: dataValues && dataValues.latitude !== "" ? dataValues.latitude : "", lng: dataValues && dataValues.longitude !== "" ? dataValues.longitude : "" },
      place_id: 1,
      title: 'Your Sapling Planted Here',
    },
  ];

  const position = {
    lat: dataValues && dataValues.latitude !== "" ? dataValues.latitude : "",
    lng: dataValues && dataValues.longitude !== "" ? dataValues.longitude : "",
  };

  const onLoad = (marker) => {
    // console.log('marker: ', marker);
  };
  return (
    <LoadScript googleMapsApiKey={config.googleMapKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={20}
        markers={markers}
        // options={function (maps) {
        //   return { mapTypeId: 'satellite' };
        // }}
        // defaultOptions={{ mapTypeControl: true }}
        options={{
          streetViewControl: false,
          draggable: true, // make map draggable
          zoomControlOptions: { position: 9 },
          keyboardShortcuts: false, // disable keyboard shortcuts
          scaleControl: true, // allow scale controle
          scrollwheel: true, // allow scroll wheel
          mapTypeControl: true,
          mapTypeId: 'satellite',
        }}
      >
        {
          /* Child components, such as markers, info windows, etc. */

          <Marker
            key='marker_1'
            onLoad={onLoad}
            position={position}
            icon={{
              url: treeidentify,
            }}
          />
        }
        <></>
      </GoogleMap>
    </LoadScript>
  );
}
