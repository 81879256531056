import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'assets/styles/pages/sidebar.scss';
import 'assets/styles/pages/dashboardstatus.scss';
import { Link } from 'gatsby';
import editiconpencil from 'assets/images/admin/edit-icon.svg';
import Viewprojectpopup from 'components/map-initiative/viewprojectpopup';
import locationicon from 'assets/images/admin/location.png';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      id="mappop"
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      className='tracklocation-popup'
      centered>
      <Modal.Header closeButton='close-button topright'></Modal.Header>
      <Modal.Body id="mappop">
        <Viewprojectpopup data={props} />
      </Modal.Body>
    </Modal>
  );
}

function Projectlocationpops(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <span className='gap-circle-icon'>
        {' '}
        <a className='circle-view' onClick={() => setModalShow(true)}>
          {' '}
          <img className='img-fluid location-icon-img' src={locationicon} alt='bellicon' />
        </a>{' '}
      </span>

      <MyVerticallyCenteredModal show={modalShow} data={props} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Projectlocationpops;
