import React from 'react';

import AdminLayout from '../../adminlayout/index';

import SEO from 'components/seo/index';

import Dashboardviewall from 'components/admin/dashboardviewall';

const Dashboardview = () => {
    return (
      <>
    
     <SEO title='Forests By Heartfulness | Initiative list' />
     <AdminLayout>
     <div>
          <Dashboardviewall />
        </div>
     </AdminLayout> 

      </>
    );
  };
  
  export default Dashboardview;