import pathtree from 'assets/images/map-initiative/Path 22295.png';

export const mapinitiativeData = [
    
        {
            id: 1,
            titleitem: 'Your Banyan Tree',
            clouddegree: 'Cloudy H.25°C | H: 27°C',
            centimeter : '153.2 Cm',
            img: pathtree,
            co: 'CO2 Sequestered:',
            kg: '51.2 Kg',
            planted: 'Planted On:',
            datetime: 'Tue, Apr 20, 2021 | 12: 45 pm',
            plantedby: 'Planted By:',
            name: 'Mari Gamesan M',
            donatetree: 'Donated Trees',
            bayantree: 'Banyan Tree',
            
          },  
    
    ]


    export const customcardData = [
      {
        id: 1,
        titleitem: 'Green Kanha ',
        trackid: 'Tracking Id:',
        serialnumber: '#101975841320',
        location: 'Location:',
        city: 'Bangalore',
        trees: 'Trees:',
        bayantree: 'Banyan Tree',
      }

    ]