import { ax } from 'services/index';

export default class PlantationListDetails {
  listPlantation(data) {
    return ax.get("planter/my-plantations", { params: data.inputs });
  }
  registerMyPlantation(payLoad) {
    return ax.post(`plantation/register`, payLoad);
  }
  listSaplings() {
    return ax.get("https://run.mocky.io/v3/9a825796-1117-4cb5-9367-ca587c563538");
  }
  submitToAdmin(payLoad) {
    return ax.post("plantation/review", payLoad);
  }
  listSpeicess(id) {
    return ax.get(`plantation/${id}`);
  }
  loadAddSpecies(payLoad) {
    return ax.post("plantation/tracking", payLoad);
  }
  loadUpdateTrackingDetails(payLoad) {
    return ax.put("plantation/tracking", payLoad);
  }

  projectList(data) {
    return ax.get("projects", { params: data.inputs });
  }
  speciesList(data) {
    return ax.get("species", { params: data.inputs });
  }
  mapSpecies(data) {
    return ax.get("plantation/map-species", { params: data.inputs });
  }
  imagesSignedUrl(data) {
    return ax.post("signedUrl", data);
  }

  // Delete Tracking 

  deletePlantationTracking(id) {
    return ax.delete(`plantation/tracking/${id}`);
  }
  speciesGrowth(payLoad) {
    https://forest-by-heartfulness-service-grpc.dev.heartfulnessinstitute.in/species/growth-status
    return ax.post("species/growth-status", payLoad);
  }
  postPlantationData(payLoad) {
    return ax.post("admin/upload-plantation-excel", payLoad);
  }

  //donor list

  donorListSearch(payLoad) {
    return ax.get("admin/donation-details?pageNumber=0&pageSize=10&toBeFiltered=true&donatedProjectId=1&mappedProjectId=2", payLoad);
  }
}
