import React from 'react';

import { Link } from 'gatsby';

import 'assets/styles/pages/mapinitiative.scss';

import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

// import mapBg from 'assets/images/map-initiative/map.png';

// import pathtree from 'assets/images/map-initiative/Path 22295.png';

import slidepic1 from 'assets/images/map-initiative/slide1.png';

import treeidentify from 'assets/images/map-initiative/tree-identify.png';

import Googlemaps from 'components/map-initiative/googlemaps';

import { mapinitiativeData } from 'assets/data/mapinitiative';

import { customcardData } from 'assets/data/mapinitiative';

import { truncate } from 'lodash';

export default function Viewprojectpopup(props) {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className='carousel-button-group'>
        {/* <div className='navigations'>
          <button onClick={() => previous()} className='me-3'>
            <i className='icon icon-arrow-left-short'></i>
          </button>
          <button onClick={() => next()}>
            <i className='icon icon-arrow-right-short'></i>
          </button>
        </div> */}
      </div>
    );
  };

  return (
    <>
      <div id="mappop" className='map-page plantpopup-page'>
        <Googlemaps mapValues={props} />
        <div className='container'>
          <div className='row'>

          </div>
        </div>
      </div>
    </>
  );
}
